<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}

.btn {
	margin-bottom: 8px;
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	padding: 1.25rem;
	flex: 1 1 auto;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<div v-if="showFunctions">
			<function @goBack="hideFunctions" :depValue="curDep.value" />
		</div>
		<div class="container-layout animated fadeIn" v-else>
			<b-form>
				<b-row v-if="Deps">
					<div class="card card-border-blue-light">
						<span id="top-department"></span>
						<b-row>
							<b-col md="8" v-if="newDep">
								<b-form-group :label="FormMSG(200, 'Prefix:')" label-for="Prefix" :label-cols="3">
									<b-form-input id="Prefix" v-model="newDep.prefix" type="text" :disabled="newDep.value == 0" />
								</b-form-group>
								<b-form-group :label="FormMSG(2, 'Department:')" label-for="depName" :label-cols="3">
									<b-form-input id="depName" v-model="newDep.message" type="text" :disabled="newDep.value == 0" />
								</b-form-group>
								<b-form-group :label="FormMSG(100, 'Category')" label-for="catName" :label-cols="3">
									<treeselect v-model="newDep.categoryLinkedToDep" :multiple="false" :options="Cats" :disabled="newDep.value == 0" />
								</b-form-group>
								<b-form-group :label="FormMSG(9, 'Reference')" label-for="depRef" :label-cols="3">
									<b-form-input id="depRef" v-model="newDep.costCenter" type="text" :disabled="newDep.value == 0" />
								</b-form-group>
							</b-col>
							<b-col md="4">
								<div
									class="w-100"
									:class="`${$screen.width <= 576 ? 'footer-fixed' : ''}`"
									:style="`left:${$screen.width <= 576 ? '0' : '15'}px;`"
								>
									<b-row>
										<b-col md="12">
											<b-button variant="primary" :disabled="this.disableSave" size="md" @click="curDepUpd()" block>
												{{ this.FormMSG(3, 'Save') }}
											</b-button>
										</b-col>
									</b-row>
								</div>
								<b-row>
									<b-col md="12">
										<b-button size="md" variant="primary" @click="NewDepartment" block>
											{{ this.FormMSG(5, 'Create a new department') }}
										</b-button>
									</b-col>
								</b-row>
								<b-row>
									<b-col md="12" v-if="newDep.value != 0">
										<b-button size="md" variant="primary" @click="GoToFunctions" block>
											{{ this.FormMSG(4, 'Functions for this department') }}
										</b-button>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
						<b-modal
							header-class="header-class-modal-doc-package"
							v-model="successModal"
							:title="FormMSG(6, 'Success!')"
							class="modal-success"
							ok-variant="success"
							ok-only
							@ok="successModal = false"
							modal-class="mui-animation"
							:fade="false"
						>
							{{ this.FormMSG(7, 'The new department name has been saved.') }}
						</b-modal>
						<b-modal
							header-class="header-class-modal-doc-package"
							v-model="successModalDelete"
							:title="FormMSG(6, 'Success!')"
							class="modal-success"
							ok-variant="success"
							ok-only
							@ok="successModalDelete = false"
							modal-class="mui-animation"
							:fade="false"
						>
							{{ this.FormMSG(8, 'Your information has been deleted.') }}
						</b-modal>
						<!--     TABLE OF Departments      use striped to highlight each even row   @row-selected="rowSelected" -->
						<b-row class="mt-2">
							<b-col cols="12" xl="12">
								<CardListBuilder v-if="$screen.width < 992" :items="Deps" :fields="depMobileFields">
									<template slot="actions" slot-scope="data">
										<b-button variant="primary" @click="rowClicked(data.item)" size="sm">
											<i class="icon-eye"></i>
										</b-button>
										<b-button size="sm" v-if="data.item.value != 0" variant="danger" @click="delItem(data.item.id)">
											<i class="icon-trash" />
										</b-button>
									</template>
								</CardListBuilder>
								<b-table
									v-if="$screen.width >= 992"
									ref="myTable"
									:hover="hover"
									sticky-header="700px"
									responsive="sm"
									selectable
									:selected-variant="selectedColor"
									:select-mode="selectMode"
									:items="Deps"
									style="text-align: left"
									:fields="depfields"
									:current-page="currentPage"
									:per-page="perPage"
									:head-variant="hv"
									bordered
									small
									@row-clicked="rowClicked"
								>
									<template #cell(rem)="data">
										<component
											:is="getLucideIcon('Trash2')"
											color="#EA4E2C"
											:size="20"
											@click="delItem(data.item.id)"
											v-if="data.item.value !== 0"
										/>
										<div v-else class="p-3"></div>
									</template>
								</b-table>
							</b-col>
						</b-row>
					</div>
				</b-row>
			</b-form>
		</div>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import Function from './function';
import languageMessages from '@/mixins/languageMessages';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import globalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { getBudgetHierarchicalCategoryJson } from '../../cruds/budget.crud';
import { removeAttributeTree } from '../../shared/utils';

const query_departments = gql`
	query {
		GetDepartments {
			id
			value
			message
			prefix
			costCenter
			category
			categoryLinkedToDep
		}
	}
`;

const mutation_curDepUpd = gql`
	mutation ($DepartmentId: ID!, $DepartmentName: String!, $NewCostCenter: String, $Prefix: String, $CategoryId: ID) {
		UpdDepartment(DepartmentId: $DepartmentId, DepartmentName: $DepartmentName, NewCostCenter: $NewCostCenter, Prefix: $Prefix, CategoryId: $CategoryId) {
			id
			value
			prefix
			message
			costCenter
		}
	}
`;

const mutation_curDepDel = gql`
	mutation ($DepartmentId: ID!, $DeleteCategory: Boolean!) {
		DelDepartment(DepartmentId: $DepartmentId, DeleteCategory: $DeleteCategory) {
			id
			value
			prefix
			message
			costCenter
		}
	}
`;

const mutation_newDep = gql`
	mutation {
		NewDepartment {
			id
			value
			message
			costCenter
		}
	}
`;

const mutation_moveDep = gql`
	mutation ($DepartmentId: ID!) {
		SwitchToDepartmentZero(DepartmentId: $DepartmentId)
	}
`;

export default {
	name: 'Department',
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	components: {
		Function,
		Treeselect
	},
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data: () => {
		return {
			hv: 'dark',
			erreur: {},
			Deps: [],
			Cats: [],
			currentPage: 1,
			perPage: 0,
			warning: '',
			stopPropagation: '',
			selectMode: 'single',
			selectedColor: 'primary',
			successModal: false,
			successModalDelete: false,
			curDep: {},
			newDep: {},
			showFunctions: false,
			oldCostCenter: 0
		};
	},
	created() {
		this.reloadData();
	},
	computed: {
		baseFields() {
			return [
				{
					key: 'costCenter',
					label: this.FormMSG(10, 'Reference'),
					sortable: true
				},
				{
					key: 'prefix',
					label: this.FormMSG(201, 'Prefix'),
					sortable: true
				},
				{
					key: 'message',
					label: this.FormMSG(22, 'Department name'),
					sortable: true
				},
				{
					key: 'rem',
					label: this.FormMSG(23, 'Remove'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		depfields() {
			return this.baseFields;
		},
		depMobileFields() {
			const keys = ['rem'];
			return this.baseFields.filter((t) => !keys.includes(t.key));
		},
		disableSave() {
			if (
				this.curDep &&
				(this.curDep.message != this.newDep.message ||
					this.curDep.costCenter != this.newDep.costCenter ||
					this.newDep.prefix != this.curDep.prefix ||
					this.curDep.categoryLinkedToDep != this.newDep.categoryLinkedToDep)
			) {
				return false;
			} else {
				return true;
			}
		}
	},
	methods: {
		curDepUpd() {
			if (this.oldCostCenter != this.newDep.costCenter) {
				let txt = this.FormMSG(
					1599,
					'Please note that modifying the cost center will also modify the cost center of the associated budget category. Are you sure you want to continue?'
				);
				this.$bvModal
					.msgBoxConfirm(txt, {
						title: this.FormMSG(24, 'Confirm'),
						size: 'md',
						buttonSize: 'md',
						okVariant: 'primary',
						cancelVariant: 'secondary',
						okTitle: this.FormMSG(1598, 'Continue'),
						noCloseOnBackdrop: true,
						cancelTitle: this.FormMSG(1597, 'Cancel'),
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then(async (v) => {
						if (v == null) return;
						if (!v) {
							return;
						} else {
							await updateDepartment();
						}
					})
					.catch((err) => console.log(err));
			} else {
				const DepartmentId = parseInt(this.newDep.id, 10);
				const DepartmentName = this.newDep.message;
				const NewCostCenter = this.newDep.costCenter;
				const Prefix = this.newDep.prefix;
				let CategoryId = null;
				if (this.newDep.categoryLinkedToDep) {
					CategoryId = this.newDep.categoryLinkedToDep;
				}
				this.$apollo
					.mutate({
						mutation: mutation_curDepUpd,
						variables: {
							DepartmentId,
							DepartmentName,
							NewCostCenter,
							Prefix,
							CategoryId
						}
					})
					.then((result) => {
						//  this.$apollo.queries.TsDays.refetch()
						// modify curDep from newDep

						this.curDep = Object.assign({}, this.newDep);
						// find object in the list and update accordingly
						for (var i = 0; i < this.Deps.length; i++) {
							if (this.Deps[i].id == result.data.UpdDepartment.id) {
								this.Deps[i].message = this.curDep.message;
								this.Deps[i].costCenter = this.curDep.costCenter;
								this.createToastForMobile(
									this.FormMSG(154, 'Department updated successfully'),
									'',
									'',
									'success',
									this.FormMSG(155, 'Success')
								);
								break;
							}
						}
						this.reloadData();
						//this.successModal = true;
					})
					.catch((error) => {
						console.log(error);
						//this.erreur = error
						var myerrorObject = {
							err: ''
						};
						myerrorObject.err = error.message;
						this.erreur = myerrorObject;
					});
			}
			const updateDepartment = async () => {
				const DepartmentId = parseInt(this.newDep.id, 10);
				const DepartmentName = this.newDep.message;
				const NewCostCenter = this.newDep.costCenter;
				const Prefix = this.newDep.prefix;
				const CategoryId = this.newDep.categoryLinkedToDep;
				await this.$apollo
					.mutate({
						mutation: mutation_curDepUpd,
						variables: {
							DepartmentId,
							DepartmentName,
							NewCostCenter,
							Prefix,
							CategoryId
						}
					})
					.then((result) => {
						//  this.$apollo.queries.TsDays.refetch()
						// modify curDep from newDep
						this.curDep = Object.assign({}, this.newDep);
						// find object in the list and update accordingly
						for (var i = 0; i < this.Deps.length; i++) {
							if (this.Deps[i].id == result.data.UpdDepartment.id) {
								this.Deps[i].message = this.curDep.message;
								this.Deps[i].costCenter = this.curDep.costCenter;
								this.Deps[i].prefix = this.curDep.prefix;
								this.createToastForMobile(
									this.FormMSG(154, 'Department updated successfully'),
									'',
									'',
									'success',
									this.FormMSG(155, 'Success')
								);
								break;
							}
						}
						this.reloadData();
						//this.successModal = true;
					})
					.catch((error) => {
						console.log(error);
						//this.erreur = error
						var myerrorObject = {
							err: ''
						};
						myerrorObject.err = error.message;
						this.erreur = myerrorObject;
					});
			};
		},
		async reloadData() {
			this.$apollo
				.query({
					query: query_departments,
					variables: {},
					// options: { fetchPolicy: 'network-only' },
					// options: { fetchPolicy: 'no-cache' },
					// force update from server
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					this.Deps = result.data.GetDepartments;
					this.curDep = this.Deps[0];
					this.oldCostCenter = this.curDep.costCenter;
					this.newDep = Object.assign({}, this.curDep);
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
			const updateTree = (tree) => {
				return tree
					.map((node) => {
						const newNode = { ...node };

						if (newNode.data.linkedToDepId !== 0) {
							delete newNode.children;
							newNode.isDisabled = true;
						} else {
							// LinkedToDepId is equal to 0, do nothing
						}

						if (newNode.data.category !== 0) {
							if (newNode.children && newNode.children.length > 0) {
								newNode.children = updateTree(newNode.children); // Recursively update the children
							}
							return newNode;
						}
					})
					.filter((node) => node !== undefined);
			};
			await getBudgetHierarchicalCategoryJson(-2).then((res) => {
				let part = removeAttributeTree(res, 'children', null);
				let parts = updateTree(part);
				// console.log(parts);
				this.Cats = [
					{
						id: -1,
						label: this.FormMSG(76, 'Select a category')
					},
					...parts
				];
			});
		},
		delItem(id) {
			if (this.isFilmSingle) {
				let txt = this.FormMSG(15910, 'Are you sure you want to delete this department?');
				this.$bvModal
					.msgBoxConfirm(txt, {
						title: this.FormMSG(24, 'Confirm'),
						size: 'md',
						buttonSize: 'md',
						okVariant: 'primary',
						okTitle: this.FormMSG(1591, 'Delete'),
						noCloseOnBackdrop: true,
						cancelTitle: this.FormMSG(1593, 'Cancel'),
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then(async (v) => {
						if (v == null) return;
						if (!v) {
							return;
						} else {
							await deleteDep(true);
						}
					})
					.catch((err) => console.log(err));
			} else {
				let txt = this.FormMSG(
					1590,
					'Categories of budgets linked to this department exist. You can move the category tree to department 0, or delete the entire category tree. What do you want to do?'
				);
				this.$bvModal
					.msgBoxConfirm(txt, {
						title: this.FormMSG(24, 'Confirm'),
						size: 'md',
						buttonSize: 'md',
						okVariant: 'primary',
						cancelVariant: 'secondary',
						okTitle: this.FormMSG(1591, 'Delete'),
						noCloseOnBackdrop: true,
						cancelTitle: this.FormMSG(1592, 'Move to the category 0'),
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then(async (v) => {
						if (v == null) return;
						if (!v) {
							await deleteDep(false);
						} else {
							await deleteDep(true);
						}
					})
					.catch((err) => console.log(err));
			}

			const deleteDep = async (deleteCategory) => {
				var DepartmentId = parseInt(id, 10);
				// console.log("departmentid:", DepartmentId);
				let DeleteCategory = deleteCategory;
				await this.$apollo
					.mutate({
						mutation: mutation_curDepDel,
						variables: {
							DepartmentId,
							DeleteCategory
						}
					})
					.then((result) => {
						// console.log("del dep results:", result);
						this.reloadData();
						this.createToastForMobile(this.FormMSG(158, 'Department deleted successfully'), '', '', 'success', this.FormMSG(155, 'Success'));
						//this.successModalDelete = true;
					})
					.catch((error) => {
						console.log(error);
						this.erreur = error;
					});
			};
			// const moveDepToZero = async () => {
			//     var DepartmentId = parseInt(id, 10);
			//     await this.$apollo
			//         .mutate({
			//             mutation: mutation_moveDep,
			//             variables: {
			//                 DepartmentId
			//             }
			//         })
			//         .then(result => {
			//             if(result) {
			//                 this.reloadData();
			//                 this.createToastForMobile(
			//                 this.FormMSG(1508, 'Department Moved successfully'),
			//                 '',
			//                 '',
			//                 'success',
			//                 this.FormMSG(155, 'Success')
			//             )
			//             }
			//         })
			//         .catch(error => {
			//             console.log(error);
			//             this.erreur = error;
			//         });
			// }
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		rowClicked(item) {
			this.curDep = item;
			this.oldCostCenter = item.costCenter;
			this.newDep = Object.assign({}, item);
			this.$nextTick(() => {
				let element = document.getElementById('top-department');
				element.scrollIntoView({ behavior: 'smooth', block: 'end' });
			});
		},
		NewDepartment() {
			this.$apollo
				.mutate({
					mutation: mutation_newDep,
					variables: {}
				})
				.then((result) => {
					// reload the Data
					this.reloadData();
					this.createToastForMobile(this.FormMSG(157, 'Department added successfully'), '', '', 'success', this.FormMSG(155, 'Success'));
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		FunctionLink(item) {
			return `/departments/${item.value.toString()}/functions`;
		},
		hideFunctions() {
			this.showFunctions = false;
		},
		GoToFunctions() {
			// copy item content into curTs structure.
			// console.log("department item put in store:", this.curDep);
			store.setCurDepartment(this.curDep);
			// console.log("department item put in store:", this.curDep);
			this.showFunctions = true;
		}
	}
};
</script>
